@import '../../styles/const.scss';

.page {
  display: grid;
  overflow: hidden;
  height: calc(100dvh - 50px);
  grid-template-rows: auto 1fr;
  max-height: calc(100dvh - 50px);
}

.categoriesWrap {
  @extend .container;
  // flex: 1;
  gap: 20px;
  height: 100%;
  display: flex;
  margin-top: 40px;
  overflow: hidden;
  max-height: calc(100% - 40px);
  // display: grid;
  // grid-template-columns: 0.4fr 0.6fr;
}

.right {
  flex: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.searchWrap,
.categories,
.subCategories,
.subCategoriesEmpty {
  gap: 12px;
  display: flex;
  padding: 24px;
  border-radius: 20px;
  flex-direction: column;
  background-color: #fff;
}

.categories,
.subCategories {
  overflow: hidden;
  padding: 20px 12px;
  position: relative;
  .itemsList {
    gap: 24px;
    padding: 0 8px 12px 0;
    margin: 12px 0;
    display: flex;
    overflow: auto;
    flex-direction: column;
    @extend .scroll;
    &.empty {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}

.categories {
  width: 40%;
}

.subCategoriesEmpty,
.subCategories {
  flex: 1;
  gap: 24px;
}

.subCategoriesEmpty {
  align-items: center;
  justify-content: center;
  .title {
    max-width: 500px;
    font-weight: 500;
    text-align: center;
    font-size: 1.125rem;
  }
}

.searchWrap {
  .titleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

button.btnCorrelate {
  flex-shrink: 0;
  min-width: 96px !important;
  padding: 3px 10px !important;
}

.primary {
  color: $primary;
}

.cat {
  gap: 24px;
  display: flex;
  min-height: 68px;
  padding: 8px 12px;
  position: relative;
  border-radius: 10px;
  align-items: flex-start;
  border: 1px solid #e8e8e8;
  justify-content: space-between;
  &.main {
    background-color: #f6f5f3;
    &.disabled {
      cursor: default;
    }
    &.showed {
      .similarFoundArrow {
        transform: rotate(0);
      }
    }
  }
  &.active {
    border-color: $primary;
    background-color: #2560a915;
  }
  .btns {
    gap: 4px;
    min-width: 87px;
    min-height: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .btn{
      min-width: 87px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .btn {
    font-size: 12px;
    font-weight: 600;
    border-color: #e8e8e8 !important;
  }
}

.moreActions{
  position: relative;

  &:hover{
    .moreActionsDropdown{
      opacity: 1;
      pointer-events: auto;
      top: 100%;
    }
  }

  &Toggle{
    height: 18px;
    width: 18px;
    padding: 0;
    display: flex;
    align-items: center;
  }
  &Dropdown{
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    background-color: #FFFFFF;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 4px 14px 0px #0000001A;
    pointer-events: none;
    opacity: 0;
    transition: $trans;
    z-index: 2;
    width: 190px;

    button{
      padding: 0 10px;
      height: 37px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      &:hover{
        background-color: rgba($primary, .1);
      }
    }
  }
}

.categories .catNameWrap {
  flex-direction: column;
}

.subCategories .catNameWrap {
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.catNameWrap {
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  &.btns {
    gap: 0.5rem;
    display: flex;
  }
}

.catName {
  font-size: 1rem;
  font-weight: 500;
}

.catNumb {
  color: $primary;
}

.categories {
  .itemsList {
    .catNameWrap {
      gap: 8px;
    }
    .catStickers {
      position: static;
    }
  }
}

.catStickers {
  gap: 6px;
  top: -11px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  .sticker {
    font-size: 12px;
    border-radius: 6px;
    padding: 4.5px 8px;
    &.yellow {
      background-color: #ffeca6;
    }
    &.blue {
      background-color: #a6eaff;
    }
    &.violet {
      background-color: #d9d2ff;
    }
    &.pink {
      background-color: #ffccee;
    }
    &.red {
      background-color: #ffcccc;
    }
    &.add {
      border-radius: 16px;
      &:hover {
        filter: brightness(1.05);
      }
    }
  }
}

.subCategories {
  .cat {
    padding: 24px;
    &.detail {
      padding: 12px 24px;
    }
    // cursor: pointer;
    &:hover {
      // border-color: $primary;
    }
    &.active {
      // cursor: default;
      // pointer-events: none;
      border-color: $green;
      background-color: $transpGreen;
    }
  }
}

.select {
  min-width: 180px;
}

.deletePopup{
  &Inner{
    text-align: center;
    align-items: center;
  }
  &Text{
    font-size: 17px;
    font-weight: 500;
  }
  &Btn{
    width: max-content;
  }
}
.createPopup, .renamePopup, .deletePopup{
  width: 496px;
  &Inner{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &Btns{
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.createPopup, .renamePopup{
  &Inner{
    margin-top: 24px;
  }
}

.createPopup{
  &Parent{
    font-size: 13px;
    font-weight: 500;
    color: $fontGreyColor;
  }
}