@import '../../styles/const.scss';

.brands {
  @extend .container;
  flex: 1;
  gap: 6px;
  display: flex;
  margin-top: 18px;
  flex-direction: column;
  &.empty {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}

.brandWrap {
  padding: 12px;
  border-radius: 20px;
  background-color: #fff;
}

.brandWrap.empty {
  .brand {
    &.main {
      border-radius: 10px;
      border: 1px solid #e8e8e8;
    }
  }
}

.brand {
  padding: 8px;
  position: relative;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  justify-content: space-between;
  &:not(.main) {
    border-radius: 6px;
  }
  &.main {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 10px 10px 0 0;
    border-bottom: none;
    background-color: #f6f5f3;
    &.disabled {
      cursor: default;
    }
    // &:hover:not(.disabled) {
    //   background-color: #ededed;
    // }
    &.showed {
      .similarFoundArrow {
        transform: rotate(0);
      }
    }
  }
}

.brand,
.brandNameWrap {
  gap: 24px;
  display: flex;
  align-items: center;
}

.brandNameWrap {
  flex-wrap: wrap;
}

.brandName {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 2px;
}

.brandStickers {
  //   top: -11px;
  gap: 6px;
  display: flex;
  //   position: absolute;
  .sticker {
    font-size: 12px;
    border-radius: 6px;
    padding: 4.5px 8px;
    &.yellow {
      background-color: #ffeca6;
    }
    &.blue {
      background-color: #a6eaff;
    }
    &.violet {
      background-color: #d9d2ff;
    }
    &.pink {
      background-color: #ffccee;
    }
    &.red {
      background-color: #ffcccc;
    }
    &.add {
      border-radius: 16px;
      &:hover {
        filter: brightness(1.05);
      }
    }
  }
}

.btnEdit {
  gap: 0.25rem;
  display: flex;
  padding: 0.5rem;
  margin: -0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  align-items: center;
  color: $fontGreyColor;
  &:hover {
    color: $fontColor;
    path {
      fill: $fontColor;
    }
  }
}

.similarFoundArrowWrap {
  gap: 24px;
  display: flex;
  font-size: 0.875rem;
  align-items: center;
}

.similarFoundArrow {
  display: flex;
  align-items: center;
  //   transition: $trans;
  transform: rotate(180deg);
}

.status {
  font-size: 12px;
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 6px;
  &.green {
    background-color: #35af501a;
    color: #35af50;
  }
  &.red {
    background-color: #af35351a;
    color: #af3535;
  }
}

.brandPercent {
  font-weight: 600;
  font-size: 0.875rem;
  &.red {
    color: #af3535;
  }
  &.green {
    color: #35af50;
  }
  &.yellow {
    color: #b4ae1f;
  }
}

.popup {
  width: 560px;
  max-width: 100%;
}

.btns {
  gap: 12px;
  display: flex;
  margin-top: 24px;
  justify-content: center;
}

.popupInput {
  margin-top: 24px;
}

.statusBtns {
  gap: 6px;
  display: flex;
  margin-top: 2px;
}

.statusActions {
  margin: -10px 0 -8px 0;
}

.listDataWrap {
  @extend .container;
  gap: 18px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 18px;
  .listData {
    gap: 6px;
    padding: 12px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    background-color: #fff;
    border: 2px solid transparent;
    &.active {
      border-color: $primary;
    }
  }
  .listDataNumb,
  .listDataTitle {
    font-size: 1.125rem;
  }
  .listDataNumb {
    font-weight: 600;
    height: 1.2rem;
  }
}

.categoriesContainer {
  @extend .container;
  gap: 12px;
  height: 100%;
  display: flex;
  margin-top: 18px;
  max-height: 80vh;
}

.categoriesWrap {
  flex: 1;
  display: grid;
  grid-template-columns: 0.35fr 0.65fr;
  border-radius: 20px;
  background-color: #fff;
}

.subCategories,
.categories {
  overflow: hidden;
  padding: 22px 12px;
  .itemsList {
    padding: 12px;
    display: flex;
    overflow: auto;
    max-height: 100%;
    padding-right: 8px;
    flex-direction: column;
    @extend .scroll;
  }
  .item {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    &.active {
      background-color: #eff2f7;
    }
  }
}

.subCategories {
  position: relative;
  .itemsList {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  .item {
    &:hover {
      background-color: #eff2f7;
    }
  }
}

.brandItemsWrap {
  gap: 4px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 0 0 10px 10px;
}

.detailingTable {
  width: 400px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
  background-color: transparent;
  td,
  th {
    padding: 3px;
    font-size: 14px;
    background-color: #fff;
  }
  th button {
    border-radius: 10px;
    width: 100%;
    &:hover {
      background-color: #f4f4f4;
    }
  }
  td {
    // cursor: copy;
    &:hover {
      background-color: #f4f4f4;
    }
    // &:active {
    //   background-color: #c5ffc4;
    // }
  }
}
