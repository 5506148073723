$primary: #2561a9;
$fontColor: #3a3a3a;
$fontGreyColor: #555555;

$bgColor: #eff2f7;

$green: #35af50;
$danger: red;
$transpGreen: #35af501a;

$trans: all 0.15s easy-in-out;

.container {
  margin: auto;
  width: 100%;
  max-width: 1400px;
  padding-left: 20px;
  padding-right: 20px;
}

.scroll {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #f6f5f3;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #c4c4c4;
  }
}
