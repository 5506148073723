@import '../../styles/const.scss';

.errorMesWrap {
  position: relative;
}
.errorMes {
  top: 2px;
  color: red;
  font-size: 13px;
  line-height: 0.8;
  position: absolute;
}

.inputWrap {
  width: 100%;
  textarea,
  input {
    width: 100%;
    padding: 9.5px;
    font-size: 16px;
    line-height: 100%;
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    background-color: transparent;
    transition: background-color, border-color 0.2s ease-in-out;
  }
  textarea {
    resize: vertical;
  }
  .inputSubWrap {
    width: 100%;
    position: relative;
  }
  .password {
    input {
      padding-right: 2.5rem;
    }
  }
  .withUnit {
    input {
      padding-right: 25px;
    }
  }
  .unit {
    top: 12.5px;
    right: 8.5px;
    position: absolute;
    color: #c4c4c4;
  }
  textarea:hover,
  input:hover {
    border-color: #82838e;
  }
  textarea:focus,
  input:focus {
    border-width: 1px;
    background-color: #fff;
    border-color: #0657f980;
  }
  ::placeholder {
    color: #b3b3b3;
  }
  label {
    display: block;
    font-size: 14px;
    color: #757575;
    margin-bottom: 0.5rem;
  }
  // error
  &.error {
    textarea,
    input {
      border-color: red;
    }
  }
}

.inputRadioWrap {
  .radioList {
    gap: 33px;
    display: flex;
  }
  .iconWrap {
    width: 20px;
    height: 20px;
    display: flex;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .radioInput {
    gap: 0.5rem;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    align-items: center;
    * {
      cursor: pointer;
    }
    &:not(.active):hover {
      circle,
      path {
        stroke: #82838e;
      }
    }
    &:not(.active):active {
      circle,
      path {
        stroke: #0657f980;
      }
    }
  }
}

.inputImgWrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  overflow: hidden;
  object-fit: cover;
  position: relative;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 2px dashed #dcdddf;
  background-color: #c4c4c4;
  &.error {
    border-color: red !important;
    background-color: #d600001c !important;
    svg path {
      stroke: red;
    }
  }
  &.fill {
    border: 0;
  }
  label {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 999;
    cursor: pointer;
    position: absolute;
  }
  input {
    width: 0;
    height: 0;
    padding: 0;
  }
  .img,
  .noImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
  }
  img {
    mix-blend-mode: multiply;
  }
  .noImg {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 15%;
      height: 15%;
      min-width: 24px;
      min-height: 24px;
    }
  }
  .delImg {
    top: 0;
    right: 0;
    opacity: 0;
    padding: 0.25rem;
    position: absolute;
    transition: $trans;
    &:hover {
      svg path {
        fill: red;
      }
    }
  }
  &:hover {
    .delImg {
      opacity: 1;
    }
  }
}

.inputImgWrap.disabled,
.inputImgWrap.disabled * {
  cursor: default;
}

.inputImgWrap.disabled .delImg,
.inputImgWrap.disabled .delImg * {
  cursor: pointer;
}

.checkbox {
  gap: 10px;
  width: 100%;
  padding: 9px;
  display: flex;
  padding: 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  line-height: 100%;
  // border-radius: 8px;
  align-items: center;
  &:hover {
    background-color: #f8f8f8;
  }
  * {
    cursor: pointer;
  }
}

.togglePasTypeBtn {
  display: flex;
  padding: 0.5rem;
  margin: -0.5rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25%;
  right: 0.5rem;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  &:hover {
    path {
      fill: #000;
    }
  }
}
