@import '../../styles/const.scss';

.auth {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authForm {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px;
  background-color: #fff;
  padding: 1rem 2rem;
}

.title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.link {
  cursor: pointer;
  padding: 0.25rem;
  align-self: center;
  font-size: 0.875rem;
  color: $fontGreyColor;
  text-decoration: underline;
  &:hover {
    color: $fontColor;
  }
}
