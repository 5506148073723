@import '../../styles/const.scss';

.headerWrap {
  background-color: #fff;
}

.header {
  @extend .container;
  padding-top: 16px;
  padding-bottom: 24px;
  .titleWrap {
    gap: 1rem;
    display: flex;
    margin-top: 12px;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .title {
    padding-top: 2px;
    font-weight: 600;
    font-size: 1.375rem;
  }
  .btnBack {
    gap: 0.1rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: $fontGreyColor;
    &::before {
      content: '';
      width: 0.65rem;
      height: 0.65rem;
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml,<svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.74409 9.85707C5.03813 9.63206 5.0858 9.22165 4.85057 8.9404L1.55496 5L4.85057 1.0596C5.0858 0.778346 5.03813 0.36794 4.74409 0.142935C4.45005 -0.0820704 4.02098 -0.0364695 3.78575 0.244787L0.149407 4.59259C-0.0498023 4.83078 -0.0498023 5.16922 0.149407 5.40741L3.78575 9.75521C4.02098 10.0365 4.45005 10.0821 4.74409 9.85707Z" fill="%23555555"/></svg>');
    }
  }
  .breadCrumbs {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    min-height: 16.1px;
    align-items: center;
    a {
      color: #a0a0a0;
      font-size: 0.875rem;
      &:last-child {
        cursor: default;
        pointer-events: none;
        color: $fontColor;
      }
      &:not(.breadCrumb:last-child) {
        display: flex;
        align-items: center;
        &::after {
          content: '>';
          margin-left: 0.5rem;
        }
        &:hover {
          color: #808080;
        }
      }
    }
  }
}
