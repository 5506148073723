@import '../../styles/const.scss';

// .products {
//   @extend .container;
//   gap: 12px;
//   display: flex;
//   margin-top: 18px;
//   flex-direction: column;
//   &.empty {
//     flex: 1;
//     align-items: center;
//     justify-content: center;
//   }
// }

.prodWrap {
  padding: 24px;
  border-radius: 20px;
  background-color: #fff;
}

.prod {
  padding: 16px;
  position: relative;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  justify-content: space-between;
  &.main {
    cursor: pointer;
    background-color: #f6f5f3;
    &.disabled {
      cursor: default;
    }
    &:hover:not(.disabled) {
      background-color: #ededed;
    }
    &.showed {
      .similarFoundArrow {
        transform: rotate(0);
      }
    }
  }
  &:not(.main) {
    min-height: 61px;
  }
}

.prod:not(.main) {
  margin-top: 24px;
}

.prod,
.prodNameWrap {
  gap: 24px;
  display: flex;
  align-items: center;
}

.prodNameWrap {
  flex-wrap: wrap;
}

.prodName {
  font-size: 1rem;
  font-weight: 500;
}

.prodStickers {
  top: -11px;
  gap: 6px;
  display: flex;
  position: absolute;
  .sticker {
    font-size: 12px;
    border-radius: 6px;
    padding: 4.5px 8px;
    &.yellow {
      background-color: #ffeca6;
    }
    &.blue {
      background-color: #a6eaff;
    }
    &.violet {
      background-color: #d9d2ff;
    }
    &.pink {
      background-color: #ffccee;
    }
    &.red {
      background-color: #ffcccc;
    }
    &.add {
      border-radius: 16px;
      &:hover {
        filter: brightness(1.05);
      }
    }
  }
}

.primary {
  color: $primary;
}

.btnEdit {
  gap: 0.25rem;
  display: flex;
  padding: 0.5rem;
  margin: -0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  align-items: center;
  color: $fontGreyColor;
  path {
    fill: $fontGreyColor;
  }
  &:hover {
    color: $fontColor;
    path {
      fill: $fontColor;
    }
  }
}

.similarFoundArrowWrap {
  gap: 24px;
  display: flex;
  font-size: 0.875rem;
  align-items: center;
}

.similarFoundArrow {
  display: flex;
  align-items: center;
  //   transition: $trans;
  transform: rotate(180deg);
}

.status {
  font-size: 12px;
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 6px;
  &.green {
    background-color: #35af501a;
    color: #35af50;
  }
  &.red {
    background-color: #af35351a;
    color: #af3535;
  }
}

.prodPercent {
  font-weight: 600;
  font-size: 0.875rem;
  &.red {
    color: #af3535;
  }
  &.green {
    color: #35af50;
  }
  &.yellow {
    color: #b4ae1f;
  }
}

.popup {
  width: 560px;
  max-width: 100%;
}

.btns {
  gap: 12px;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  .btn{
    min-width: 87px;
    padding-left: 0;
    padding-right: 0;
  }
}

.moreActions{
  position: relative;

  &:hover{
    .moreActionsDropdown{
      opacity: 1;
      pointer-events: auto;
      top: 100%;
    }
  }

  &Toggle{
    height: 18px;
    width: 18px;
    padding: 0;
    display: flex;
    align-items: center;
  }
  &Dropdown{
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    background-color: #FFFFFF;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 4px 14px 0px #0000001A;
    pointer-events: none;
    opacity: 0;
    transition: $trans;
    z-index: 2;
    width: 190px;

    button{
      padding: 0 10px;
      height: 37px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      &:hover{
        background-color: rgba($primary, .1);
      }
    }
  }
}

.popupInput {
  margin-top: 24px;
}

.statusBtns {
  gap: 6px;
  display: flex;
  margin-top: 2px;
}

.statusActions {
  margin: -10px 0 -8px 0;
}

.brandsContainer,
.categoriesContainer {
  @extend .container;
  gap: 12px;
  height: 100%;
  display: flex;
  margin-top: 18px;
  max-height: 80vh;
}

.brandsWrap,
.categoriesWrap {
  flex: 1;
  display: grid;
  grid-template-columns: 0.35fr 0.65fr;
  border-radius: 20px;
  background-color: #fff;
}

.brandsWrap,
.subCategories,
.categories {
  overflow: hidden;
  padding: 22px 12px;
  .itemsList {
    padding: 12px;
    display: flex;
    overflow: auto;
    max-height: 100%;
    padding-right: 8px;
    flex-direction: column;
    @extend .scroll;
  }
  .item {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    &.active {
      background-color: #eff2f7;
    }
    &:hover {
      background-color: #eff2f7;
    }
  }
}

.brandsWrap {
  grid-template-columns: 1fr;
  .itemsList {
    display: grid;
    align-self: flex-start;
    // grid-template-rows: min-content;
    grid-template-columns: repeat(4, 1fr);
  }
}

.categories {
  display: flex;
  flex-direction: column;
  .itemsList {
    flex: 1;
    gap: 5px;
  }
}

.subCategories {
  display: flex;
  flex-direction: column;
  position: relative;
  .itemsList {
    gap: 10px;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

// new product

.masterNum {
  margin-top: 12px;
  margin-left: 12px;
}

.page {
  display: grid;
  overflow: hidden;
  height: calc(100dvh - 50px);
  grid-template-rows: auto 1fr;
  max-height: calc(100dvh - 50px);
}

.productsWrap {
  @extend .container;
  // flex: 1;
  gap: 20px;
  height: 100%;
  display: flex;
  margin-top: 40px;
  overflow: hidden;
  max-height: calc(100% - 40px);
  // display: grid;
  // grid-template-columns: 0.4fr 0.6fr;
}

.right {
  flex: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.searchWrap,
.products,
.subProducts,
.subProductsEmpty {
  gap: 12px;
  display: flex;
  padding: 24px;
  border-radius: 20px;
  flex-direction: column;
  background-color: #fff;
}

.products,
.subProducts {
  padding: 0;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  .itemsList {
    gap: 24px;
    padding: 12px;
    margin: 12px 0;
    display: flex;
    overflow: auto;
    padding-right: 8px;
    flex-direction: column;
    @extend .scroll;
    &.empty {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}

.catSearch {
  margin-left: 12px;
  margin-bottom: 12px;
}

.products {
  gap: 0;
  width: 40%;
  height: 100%;
  margin-top: 0;
  .itemsList {
    margin-top: 0;
  }
}

.subProductsEmpty,
.subProducts {
  flex: 1;
  gap: 24px;
}

.subProductsEmpty {
  align-items: center;
  justify-content: center;
  .title {
    max-width: 500px;
    font-weight: 500;
    text-align: center;
    font-size: 1.125rem;
  }
}

.searchWrap {
  .titleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

button.btnCorrelate {
  flex-shrink: 0;
  min-width: 96px !important;
  padding: 3px 10px !important;
}

.cat {
  gap: 24px;
  display: flex;
  min-height: 68px;
  padding: 8px 12px;
  position: relative;
  border-radius: 10px;
  align-items: flex-start;
  border: 1px solid #e8e8e8;
  justify-content: space-between;
  &.main {
    background-color: #f6f5f3;
    &.disabled {
      cursor: default;
    }
    &.showed {
      .similarFoundArrow {
        transform: rotate(0);
      }
    }
  }
  &.active {
    border-color: $primary;
    background-color: #2560a915;
  }
  .btns {
    gap: 10px;
    display: flex;
    margin-top: 0;
  }
  .btn {
    font-size: 12px;
    font-weight: 600;
    width: 87px !important;
    border-color: #e8e8e8 !important;
  }
}

.products .catNameWrap {
  flex-direction: column;
}
.catNameWrap {
  flex: 1;
  gap: 12px;
  display: flex;
  // flex-wrap: wrap;
}

.catName {
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
}

.catNumb {
  color: $primary;
}

.products {
  .itemsList {
    .catNameWrap {
      gap: 8px;
    }
    .catStickers {
      position: static;
    }
  }
}

.catStickers {
  gap: 6px;
  top: -11px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  .sticker {
    font-size: 12px;
    border-radius: 6px;
    padding: 4.5px 8px;
    &.yellow {
      background-color: #ffeca6;
    }
    &.blue {
      background-color: #a6eaff;
    }
    &.violet {
      background-color: #d9d2ff;
    }
    &.pink {
      background-color: #ffccee;
    }
    &.red {
      background-color: #ffcccc;
    }
    &.add {
      border-radius: 16px;
      &:hover {
        filter: brightness(1.05);
      }
    }
  }
}

.subProducts {
  .cat {
    padding: 24px;
    cursor: pointer;
    &:hover {
      border-color: $primary;
    }
    &.active {
      // cursor: default;
      // pointer-events: none;
      border-color: $green;
      background-color: $transpGreen;
    }
    &.withBtnMaster {
      cursor: unset;
    }
  }
  .catNameWrap {
    align-items: flex-start;
  }
  .btnMaster {
    // font-size: ;
  }
}

.catPercent {
  &.green {
    color: $green;
  }
  &.yellow {
    color: yellow;
  }
  &.red {
    color: red;
  }
}
.select {
  min-width: 180px;
}

.changeCategoryPopup{
  &Window{
    width: 496px;
  }
  &Text{
    margin: 12px 0 24px;
    font-size: 13px;
    span{
      font-weight: bold;
      color: $primary;
    }
  }
  &Btns{
    display: flex;
    gap: 12px;
    margin-top: 24px;
  }
  &List{
    overflow-y: auto;
    max-height: 188px;
    @extend .scroll;
    margin-top: 8px;
    padding-right: 6px;
    &Loader{
      height: 188px;
      position: relative;
    }
  }
  &Category{
    height: 28px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    transition: $trans;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: 13px;
    cursor: pointer;
    &:hover, &_active{
      background-color: $bgColor;
    }
    &_active svg{
      margin-left: auto;
      color: $primary;
    }
    i{
      margin-right: 3px;
      display: block;
      width: 4px;
      height: 4px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: $primary;
    }
    span:not(:first-child){
      margin-left: 8px;
    }
  }
  &Inner{
    &_success{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }
  &SuccessText{
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    span{
      font-weight: 700;
      color: $primary;
    }
  }
}