@import './const.scss';

body,
html,
#root {
  min-height: 100dvh;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

body {
  display: flex;
  font-size: 16px;
  color: $fontColor;
  font-family: 'Inter';
  flex-direction: column;
  background-color: $bgColor;
}

#root {
  display: flex;
}

.main {
  flex: 1;
  width: 100%;
  display: flex;
  padding-bottom: 50px;
  flex-direction: column;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

.link {
  display: block;
  padding: 0;
  margin: 0;
}

a.btn,
button.btn {
  display: flex;
  font-size: 14px;
  min-height: 40px;
  min-width: 150px;
  font-weight: 500;
  padding: 8px 20px;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #fff;
  border: 1px solid transparent;
  &.light {
    color: #3a3a3a !important;
    background-color: #fff;
    border-color: #c4c4c4;
  }
  &.grey {
    color: #3a3a3a !important;
    background-color: #f6f5f3;
    border-color: #e8e8e8;
    &:hover {
      background-color: #eff2f7;
      border-color: #2561a9;
    }
  }
  &.dark {
    color: #fff !important;
    background-color: #2561a9;
    border-color: #2561a9;
  }
  &.green {
    color: #35af50 !important;
    background-color: #35af501a;
    border-color: #35af50;
  }
  &.red {
    color: #db2424 !important;
    background-color: #db24241a;
    border-color: #db2424;
    &:hover {
      border-color: #db2424;
      filter: brightness(0.95);
    }
  }
  &.small {
    width: auto;
    min-width: 62px;
    min-height: 22px;
    padding: 5px 10px;
    border-radius: 6px;
  }
  &:hover {
    border-color: #c4c4c4;
    filter: brightness(0.95);
  }
  &:disabled {
    cursor: default;
    pointer-events: none;
  }
}

@font-face {
  font-weight: 400;
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Regular.ttf');
}

@font-face {
  font-weight: 500;
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Medium.ttf');
}

@font-face {
  font-weight: 600;
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-SemiBold.ttf');
}

@font-face {
  font-weight: 700;
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Bold.ttf');
}

@font-face {
  font-weight: 800;
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-ExtraBold.ttf');
}
