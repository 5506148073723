@import '../../styles/const.scss';

.menu {
  @extend .container;
  gap: 20px;
  display: grid;
  margin-top: 20px;
  align-self: flex-start;
  grid-template-columns: 1fr 1fr;
}

.item {
  height: 160px;
  display: flex;
  background: #fff;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
  button {
    margin-top: 20px;
  }
}
