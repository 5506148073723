@import '../../styles/const.scss';

$rad: 5px;

.select {
  position: relative;
}
.listWrap {
  left: 0;
  right: 0;
  height: 0;
  top: 100%;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  border-radius: $rad;
}
.list {
  @extend .scroll;
  overflow-y: auto;
  max-height: 200px;
}
.item,
.selected {
  width: 100%;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  padding: 10px 12px;
}
.item {
  cursor: pointer;
  transition: $trans;
  &.active {
    background-color: #2561a91a;
  }
  &:active:not(.active) {
  }
  &.icon {
    padding: 0.25rem 0.75rem;
  }
  &.color {
    margin-top: 0.2rem;
    color: transparent;
    &:first-child {
      margin-top: 0;
    }
  }
  &.active.color {
    // filter: brightness(1.1);
  }
}
.selected {
  gap: 8px;
  display: flex;
  align-items: center;
  border-radius: $rad;
  border: 1px solid #e2e2e2;
  justify-content: space-between;
  &.icon {
    padding: 0.25rem 0.75rem;
  }
}
.arrow {
  width: 10px;
  height: 5px;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
    // transition: $trans ;
  }
}
.opened {
  &.sort {
    .selected {
      path {
        fill: none;
      }
    }
  }
  .selected {
  }
  .arrow {
    svg {
      transform: rotate(0);
    }
  }
  .listWrap {
    z-index: 99;
    height: auto;
    margin-top: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 14px 0px #0000001a;
  }
}

.sort {
  .selected {
    path {
    }
  }
}
.topLabel {
  display: block;
  margin-bottom: 0.25rem;
}

.selectIcon {
  display: flex;
}

.btnAdd {
  //   @extend .btn;
  margin: 0.25rem;
  min-height: auto;
  padding: 0.25rem 0.75rem;
  width: calc(100% - 0.5rem);
  justify-content: space-between;
}

@media (hover) {
  .selected:hover {
  }
  .opened .selected:hover {
  }
  .btnAdd:hover {
  }
  .sort .selected:hover {
    path {
    }
  }
  .item {
    &:hover,
    &:active {
    }
    &:hover:not(.active),
    &:active:not(.active) {
    }
    &.active {
      &:hover,
      &:active {
      }
    }
    &.color {
      &:hover,
      &:active {
        color: transparent;
        filter: brightness(1.25);
      }
    }
  }

  .selectBlock {
    .item:hover {
      path {
      }
    }
  }
  .paletteSelect:hover .paletteIcon,
  .paletteSelect:active .paletteIcon {
    path {
      fill: none;
    }
  }
}
