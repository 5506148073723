@import '../../styles/const.scss';

.wrap {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: #201d1d4d;
}

.window {
  padding: 32px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  background-color: #fff;
  &.loading * {
    opacity: 0;
    pointer-events: none;
  }
}

.btnClose {
  margin: -14px -14px 0 0;
  align-self: flex-end;
}
.title {
  font-size: 20px;
  font-weight: 400;
}

.popupAgreementWindow {
  width: 500px;
  .title {
    font-weight: 500;
    text-align: center;
    font-size: 1.25rem;
  }
  .btns {
    gap: 0.5rem;
    display: grid;
    margin-top: 1.5rem;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
  }
}

.popupAgreement {
  position: relative;
  .errMes {
    opacity: 0;
  }
  &.withError {
    *:not(.errMes) {
      opacity: 0;
      cursor: default;
      pointer-events: none;
    }
    .errMes {
      opacity: 1;
    }
  }
}

.errMes {
  top: 50%;
  left: 50%;
  width: 100%;
  color: $danger;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
}
